import { isPlatformBrowser } from "@angular/common";
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Signal,
  WritableSignal,
  effect,
  signal,
} from "@angular/core";

export type Theme = "dark" | "light";

@Injectable({
  providedIn: "root",
})
export class ThemeSwitcherService {
  private _currentTheme: WritableSignal<Theme | null> = signal(null);
  private localStorageThemeKey = "theme";

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  get currentTheme(): Signal<Theme | null> {
    return this._currentTheme.asReadonly();
  }

  enableTheme(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    effect(() => {
      if (this._currentTheme() === "light") {
        document.body.classList.add("light-theme");
        document.body.style.colorScheme = "light";
        localStorage.setItem(this.localStorageThemeKey, "light");
      } else {
        document.body.classList.remove("light-theme");
        document.body.style.colorScheme = "dark";
        localStorage.setItem(this.localStorageThemeKey, "dark");
      }
    });

    const fromLocalStorage: Theme | string | null = localStorage.getItem(
      this.localStorageThemeKey
    );

    if (this.isValidThemeValue(fromLocalStorage)) {
      this._currentTheme.set(fromLocalStorage);
      return;
    }

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      this._currentTheme.set("dark");
    } else {
      this._currentTheme.set("light");
    }
  }

  switchTheme(): void {
    this._currentTheme.update((value) => (value === "dark" ? "light" : "dark"));
  }

  private isValidThemeValue = (value: string | null): value is Theme => {
    return (value as Theme) === "dark" || (value as Theme) === "light";
  };
}
